import React from 'react'
import heroImg from "../Assets/hero-img.png";
import aboutimg from '../Assets/about-img.jpg'
import featureImg from '../Assets/feature.png'
import team1 from '../Assets/team-1.jpg'
import team3 from '../Assets/team-3.jpg'
import team2 from '../Assets/team-2.jpg'
import team4 from '../Assets/team-4.jpg'
import project1 from '../Assets/project-1.jpg'
import project2 from '../Assets/project-2.jpg'
import project3 from '../Assets/project-3.jpg'
import newsletterImg from '../Assets/newsletter.png'
const Project = () => {
  return (
    <>
{/* Hero Start */}
<div className="container-fluid pt-5 bg-primary hero-header">
      <div className="container pt-5">
        <div className="row g-5 pt-5">
          <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
            <h1 className="display-4 text-white mb-4 animated slideInRight">Our Projects</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0">
                <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
                <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                <li className="breadcrumb-item text-white active" aria-current="page">Our Projects</li>
              </ol>
            </nav>
          </div>
          <div className="col-lg-6 align-self-end text-center text-lg-end">
            <img className="img-fluid" src={heroImg} alt="" style={{ maxHeight: '300px' }} />
          </div>
        </div>
      </div>
    </div>
{/* Hero End */}

{/* Case End */}
<div className="container-fluid bg-light py-5">
      <div className="container py-5">
        <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
          <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Case Study</div>
          <h1 className="mb-4">Explore Our Recent AI Case Studies</h1>
        </div>
        <div className="row g-4">
          <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
            <div className="case-item position-relative overflow-hidden rounded mb-2">
              <img className="img-fluid" src={project1} alt="" />
              <a className="case-overlay text-decoration-none" href="">
                <small>Robotic Automation</small>
                <h5 className="lh-base text-white mb-3">Lorem elitr magna stet eirmod labore amet labore clita
                </h5>
                <span className="btn btn-square btn-primary"><i className="fa fa-arrow-right"></i></span>
              </a>
            </div>
          </div>
          <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
            <div className="case-item position-relative overflow-hidden rounded mb-2">
              <img className="img-fluid" src={project2} alt="" />
              <a className="case-overlay text-decoration-none" href="">
                <small>Machine learning</small>
                <h5 className="lh-base text-white mb-3">Lorem elitr magna stet eirmod labore amet labore clita
                </h5>
                <span className="btn btn-square btn-primary"><i className="fa fa-arrow-right"></i></span>
              </a>
            </div>
          </div>
          <div className="col-lg-4 wow fadeIn" data-wow-delay="0.7s">
            <div className="case-item position-relative overflow-hidden rounded mb-2">
              <img className="img-fluid" src={project3} alt="" />
              <a className="case-overlay text-decoration-none" href="">
                <small>Predictive Analysis</small>
                <h5 className="lh-base text-white mb-3">Lorem elitr magna stet eirmod labore amet labore clita
                </h5>
                <span className="btn btn-square btn-primary"><i className="fa fa-arrow-right"></i></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Case End */}

    {/* FAQ Start */}

    <div className="container-fluid py-5">
      <div className="container py-5">
        <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
          <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Popular FAQs</div>
          <h1 className="mb-4">Frequently Asked Questions</h1>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="accordion" id="accordionFAQ1">
              <div className="accordion-item wow fadeIn" data-wow-delay="0.1s">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    How to build a website?
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
                      data-bs-parent="#accordionFAQ1">
                  <div className="accordion-body">
                    Dolor nonumy tempor elitr et rebum ipsum sit duo duo. Diam sed sed magna et magna diam aliquyam amet dolore ipsum erat duo. Sit rebum magna duo labore no diam.
                  </div>
                </div>
              </div>
              {/* Add more accordion items here */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="accordion" id="accordionFAQ2">
              <div className="accordion-item wow fadeIn" data-wow-delay="0.5s">
                <h2 className="accordion-header" id="headingFive">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    Will you maintain my site for me?
                  </button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive"
                      data-bs-parent="#accordionFAQ2">
                  <div className="accordion-body">
                    Dolor nonumy tempor elitr et rebum ipsum sit duo duo. Diam sed sed magna et magna diam aliquyam amet dolore ipsum erat duo. Sit rebum magna duo labore no diam.
                  </div>
                </div>
              </div>
              {/* Add more accordion items here */}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="accordion" id="accordionFAQ1">
              <div className="accordion-item wow fadeIn" data-wow-delay="0.1s">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    How to build a website?
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
                      data-bs-parent="#accordionFAQ1">
                  <div className="accordion-body">
                    Dolor nonumy tempor elitr et rebum ipsum sit duo duo. Diam sed sed magna et magna diam aliquyam amet dolore ipsum erat duo. Sit rebum magna duo labore no diam.
                  </div>
                </div>
              </div>
              {/* Add more accordion items here */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="accordion" id="accordionFAQ2">
              <div className="accordion-item wow fadeIn" data-wow-delay="0.5s">
                <h2 className="accordion-header" id="headingFive">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    Will you maintain my site for me?
                  </button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive"
                      data-bs-parent="#accordionFAQ2">
                  <div className="accordion-body">
                    Dolor nonumy tempor elitr et rebum ipsum sit duo duo. Diam sed sed magna et magna diam aliquyam amet dolore ipsum erat duo. Sit rebum magna duo labore no diam.
                  </div>
                </div>
              </div>
              {/* Add more accordion items here */}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="accordion" id="accordionFAQ1">
              <div className="accordion-item wow fadeIn" data-wow-delay="0.1s">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    How to build a website?
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
                      data-bs-parent="#accordionFAQ1">
                  <div className="accordion-body">
                    Dolor nonumy tempor elitr et rebum ipsum sit duo duo. Diam sed sed magna et magna diam aliquyam amet dolore ipsum erat duo. Sit rebum magna duo labore no diam.
                  </div>
                </div>
              </div>
              {/* Add more accordion items here */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="accordion" id="accordionFAQ2">
              <div className="accordion-item wow fadeIn" data-wow-delay="0.5s">
                <h2 className="accordion-header" id="headingFive">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    Will you maintain my site for me?
                  </button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive"
                      data-bs-parent="#accordionFAQ2">
                  <div className="accordion-body">
                    Dolor nonumy tempor elitr et rebum ipsum sit duo duo. Diam sed sed magna et magna diam aliquyam amet dolore ipsum erat duo. Sit rebum magna duo labore no diam.
                  </div>
                </div>
              </div>
              {/* Add more accordion items here */}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="accordion" id="accordionFAQ1">
              <div className="accordion-item wow fadeIn" data-wow-delay="0.1s">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    How to build a website?
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
                      data-bs-parent="#accordionFAQ1">
                  <div className="accordion-body">
                    Dolor nonumy tempor elitr et rebum ipsum sit duo duo. Diam sed sed magna et magna diam aliquyam amet dolore ipsum erat duo. Sit rebum magna duo labore no diam.
                  </div>
                </div>
              </div>
              {/* Add more accordion items here */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="accordion" id="accordionFAQ2">
              <div className="accordion-item wow fadeIn" data-wow-delay="0.5s">
                <h2 className="accordion-header" id="headingFive">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    Will you maintain my site for me?
                  </button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive"
                      data-bs-parent="#accordionFAQ2">
                  <div className="accordion-body">
                    Dolor nonumy tempor elitr et rebum ipsum sit duo duo. Diam sed sed magna et magna diam aliquyam amet dolore ipsum erat duo. Sit rebum magna duo labore no diam.
                  </div>
                </div>
              </div>
              {/* Add more accordion items here */}
            </div>
          </div>
        </div>

      </div>
    </div>

    {/* FAQ End */}

    {/* Newsletter Start */}

    <div className="container-fluid bg-primary newsletter py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div
              className="col-md-5 ps-lg-0 pt-5 pt-md-0 text-start wow fadeIn"
              data-wow-delay="0.3s"
            >
              <img className="img-fluid" src={newsletterImg} alt="" />
            </div>
            <div
              className="col-md-7 py-5 newsletter-text wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="btn btn-sm border rounded-pill text-white px-3 mb-3">
                Newsletter
              </div>
              <h1 className="text-white mb-4">
                Let's subscribe to the newsletter
              </h1>
              <div className="position-relative w-100 mt-3 mb-2">
                <input
                  className="form-control border-0 rounded-pill w-100 ps-4 pe-5"
                  type="text"
                  placeholder="Enter Your Email"
                  style={{ height: "48px" }}
                />
                <button
                  type="button"
                  className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"
                >
                  <i className="fa fa-paper-plane text-primary fs-4"></i>
                </button>
              </div>
              <small className="text-white-50">
                Diam sed sed dolor stet amet eirmod
              </small>
            </div>
          </div>
        </div>
      </div>


    {/* Newsletter End */}

    </>
  )
}

export default Project
