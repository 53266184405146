import React from 'react'
import '../css/Style.css'
import '../css/bootstrap.min.css'
import '../lib/animate/animate.min.css'
import '../lib/owlcarousel/assets/owl.carousel.min.css'
import heroImg from "../Assets/hero-img.png";
import Hero1 from "../Assets/Hero1.png";
import aboutimg from '../Assets/about-img.jpg'
import featureImg from '../Assets/feature.png'
import team1 from '../Assets/team-1.jpg'
import team3 from '../Assets/team-3.jpg'
import team2 from '../Assets/team-2.jpg'
import team4 from '../Assets/team-4.jpg'
import newsletterImg from '../Assets/newsletter.png'

const Services = () => {
  return (
    <>

    {/* Hero */}
     <div className="container-fluid pt-3 bg-primary hero-header">
      <div className="container pt-3">
        <div className="row g-5 pt-5">
          <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
            <h1 className="display-4 text-white mb-4 animated slideInRight">Our Services</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0" style={{backgroundColor:'inherit'}}>
                <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
                <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                <li className="breadcrumb-item text-white active" aria-current="page">Our Services</li>
              </ol>
            </nav>
          </div>
          <div className="col-lg-6 align-self-end text-center text-lg-end">
            <img
              className="img-fluid"
              src={Hero1}
              alt=""
              style={{ maxHeight: '400px',width:'350px' }}
            />
          </div>
        </div>
      </div>
    </div>
    {/* Hero */}

    {/* Services */}
    <div className="container-fluid bg-light mt-5 py-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
              <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
                Our Services
              </div>
              <h1 className="mb-4">
                Explore our outstanding AI solutions tailored for your business.
              </h1>
              <p className="mb-4">
                As the world advances with innovation, finding the right
                robotics partner is essential. Shreyasi Robotics is the leading
                option for businesses that want to adopt automation. Here’s how
                working with us can benefit your success.
              </p>
              {/* <a className="btn btn-primary rounded-pill px-4" href="">
                Read More
              </a> */}
            </div>
            <div className="col-lg-7">
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="row g-4">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                      <div
                        className="service-item d-flex flex-column justify-content-center text-center rounded"
                        style={{ height: "400px" }}
                      >
                        <div className="service-icon btn-square">
                          <i className="fa fa-robot fa-2x"></i>
                        </div>
                        <h5 className="mb-3">Robo Wait-Staff Experience</h5>
                        <p>
                          Robo Wait-Staff by Shreyasi Robotics boosts
                          hospitality. It enhances service, simplifies
                          operations, and sets you apart. Our state-of-the-art
                          robotics ensure quality and speed, offering a special
                          and lasting dining experience.
                        </p>
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                      <div
                        className="service-item d-flex flex-column justify-content-center text-center rounded"
                        style={{ height: "400px" }}
                      >
                        <div className="service-icon btn-square">
                          <i className="fa fa-power-off fa-2x"></i>
                        </div>
                        <h5 className="mb-3">Robot Forklift</h5>
                        <p>
                          Robot Forklift by Shreyasi Robotics upgrades
                          logistics. It solves manual handling issues with
                          accurate, fast, and safe material handling. Our
                          robotics technology enables your logistics processes
                          for higher productivity and lower costs.
                        </p>
                        {/* <a className="btn px-3 mt-auto mx-auto" href="">
                          Read More
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 pt-md-4">
                  <div className="row g-4">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                      <div
                        className="service-item d-flex flex-column justify-content-center text-center rounded"
                        style={{ height: "400px" }}
                      >
                        <div className="service-icon btn-square">
                          <i className="fa fa-graduation-cap fa-2x"></i>
                        </div>
                        <h5 className="mb-3">Humanoid HR Solutions</h5>
                        <p>
                          Shreyasi Robotics offers a humanoid technology that
                          optimizes your recruitment process. From screening to
                          onboarding, our technology transforms HR practices for
                          a smooth and smart hiring experience.
                        </p>
                        {/* <a className="btn px-3 mt-auto mx-auto" href="">
                          Read More
                        </a> */}
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.7s">
                      <div
                        className="service-item d-flex flex-column justify-content-center text-center rounded"
                        style={{ height: "400px" }}
                      >
                        <div className="service-icon btn-square">
                          <i className="fa fa-brain fa-2x"></i>
                        </div>
                        <h5 className="mb-3">Predictive Analysis</h5>
                        <p>
                          Erat ipsum justo amet duo et elitr dolor, est duo duo
                          eos lorem sed diam stet diam sed stet lorem.
                        </p>
                        {/* <a className="btn px-3 mt-auto mx-auto" href="">
                          Read More
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    {/* Services End */}

    {/* Newsletter Start */}

    {/* <div className="container-fluid bg-primary newsletter py-5">
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-md-5 ps-lg-0 pt-5 pt-md-0 text-start wow fadeIn" data-wow-delay="0.3s">
            <img className="img-fluid" src={newsletterImg} alt="" />
          </div>
          <div className="col-md-7 py-5 newsletter-text wow fadeIn" data-wow-delay="0.5s">
            <div className="btn btn-sm border rounded-pill text-white px-3 mb-3">Newsletter</div>
            <h1 className="text-white mb-4">Let's subscribe the newsletter</h1>
            <div className="position-relative w-100 mt-3 mb-2">
              <input
                className="form-control border-0 rounded-pill w-100 ps-4 pe-5"
                type="text"
                placeholder="Enter Your Email"
                style={{ height: '48px' }}
              />
              <button type="button" className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2">
                <i className="fa fa-paper-plane text-primary fs-4"></i>
              </button>
            </div>
            <small className="text-white-50">Diam sed sed dolor stet amet eirmod</small>
          </div>
        </div>
      </div>
    </div> */}
    {/* Newsletter End */}

    

    </>
  )
}

export default Services
