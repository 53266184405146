import {BrowserRouter as Router,Route,Routes } from 'react-router-dom'
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import Footer from './Components/Footer';
import About from './Components/About';
import Services from './Components/Services';
import Project from './Components/Project';
import Contact from './Components/Contact';
import Spinner from './Components/Spinner';
function App() {
  return (
    <Router>
    <Navbar/>
    {/* <Spinner/> */}
      <Routes>
         <Route path='/' element={<Home />} /> 
         <Route path='About' element={<About/>} />
         <Route path='/Services' element={<Services/>} />
         <Route path='/Project' element={<Project/>} />
         <Route path='/Contact' element={<Contact/>} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
