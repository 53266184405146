import React from "react";
import { Breadcrumb, BreadcrumbItem, Img, background, color } from "@chakra-ui/react";
import '../css/Style.css'
import '../css/bootstrap.min.css'
import '../lib/animate/animate.min.css'
import '../lib/owlcarousel/assets/owl.carousel.min.css'
import Hero1 from "../Assets/Hero1.png";
import aboutimg from '../Assets/AboutUsImg.png'

import { Link } from "react-router-dom";
const About = () => {
  return (
    <>
      <div className="container-fluid pt-3 bg-primary hero-header">
        <div className="container pt-3">
          <div className="row g-5 pt-5">
            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
              <h1 className="display-4 text-white mb-4 animated slideInRight">
                About Us
              </h1>
              <nav aria-label="breadcrumb" >
                <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0" style={{backgroundColor:'inherit'}} >
                  <li className="breadcrumb-item">
                    <Link to={'/'} className="text-white" >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={'/Contact'} className="text-white" href="/Contact">
                      Contact
                    </Link>
                  </li>
                  {/* <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    About Us
                  </li> */}
                </ol>
              </nav>
              {/* <Breadcrumb
      separator="/"
      color="white"
      fontSize={{ base: "sm", lg: "lg" }}
      mb="0"
      justifyContent={{ base: "center", lg: "center" }}
    >
      <BreadcrumbItem ml={{ base: -2, lg: -30 }}>
        <a className="text-white" href="#">
          Home
        </a>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <a className="text-white" href="#">
          Pages
        </a>
      </BreadcrumbItem>
      <BreadcrumbItem isCurrentPage>
        <a className="text-white" href="#">
          About Us
        </a>
      </BreadcrumbItem>
    </Breadcrumb> */}
            </div>
            <div className="col-lg-6 align-self-end text-center text-lg-end">
              <img
                className="img-fluid"
                src={Hero1}
                alt=""
                style={{ maxHeight: "400px",width:'350px'  }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img">
                <img className="img-fluid" src={aboutimg} alt="About Us" />
                {/* <Img src={aboutimg} /> */}
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
                About Us
              </div>
              <h1 className="mb-4">
                We Make Your Business Smarter with Artificial Intelligence
              </h1>
              <p className="mb-4">
                In a world driven by innovation, choosing the right partner for
                your robotics needs is crucial. At Shreyasi Robotics, we stand
                out as the premier choice for businesses seeking to embrace the
                future of automation.
              </p>
              <p className="mb-4">
                Here's why choosing us is a strategic decision for your success.
              </p>
              <div className="row g-3">
                <div className="col-sm-12">
                  <h6 className="mb-3">
                    <i className="fa fa-check text-primary me-2"></i>Our Vision:
                    Pioneering the Future of Robotics
                  </h6>
                </div>
                <div className="col-sm-12">
                  <h6 className="mb-0">
                    <i className="fa fa-check text-primary me-2"></i>Our
                    Mission: Enabling Businesses with Technology
                  </h6>
                </div>
              </div>
              <div className="d-flex align-items-center mt-4">
                {/* <a className="btn btn-primary rounded-pill px-4 me-3" href="">
                  Read More
                </a> */}
                <a className="btn btn-outline-primary btn-square me-3" href="">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-outline-primary btn-square me-3" href="">
                  <i className="fab fa-twitter"></i>
                </a>
                <a className="btn btn-outline-primary btn-square me-3" href="">
                  <i className="fab fa-instagram"></i>
                </a>
                <a className="btn btn-outline-primary btn-square" href="">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </>
  );
};

export default About;
