import React, { useEffect } from "react";
import '../css/Style.css'
import '../css/bootstrap.min.css'
import '../lib/animate/animate.min.css'
import '../lib/owlcarousel/assets/owl.carousel.min.css'
import Hero1 from "../Assets/Hero1.png";
import aboutimg from '../Assets/AboutUsImg.png'
import  WhyChooseUs from '../Assets/WhyChooseUs.png'
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <>
      <div className="container-fluid pt-5 bg-primary hero-header mb-5">
        <div className="container pt-5">
          <div className="row g-5 pt-5">
            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
              <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">
                Shreyasi Robotics
              </div>
              <h1 className="display-4 text-white mb-4 animated slideInRight">
                Transforming Businesses with Robotics Innovation
              </h1>
              <p className="text-white mb-4 animated slideInRight">
                Revolutionize your workforce with our Humanoid HR, Robotic
                Forklift and optimize your restaurant operations with our Robo
                Wait-Staff.
              </p>
              {/* <a
                href=""
                className="btn btn-light py-sm-3 px-sm-5 rounded-pill me-3 animated slideInRight"
              >
                Read More
              </a> */}
              <Link
                to={"/Contact"}
                className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight"
              >
                Contact Us
              </Link>
            </div>
            <div className="col-lg-6 align-self-end text-center text-lg-end">
              <img
                className="img-fluid"
                src={Hero1}
                style={{ height: "500px" }}
                alt=""
              />
              {/* <Img src={img1} height={500} width={550} /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img">
                <img className="img-fluid" src={aboutimg} alt="About Us" />
                {/* <Img src={aboutimg} /> */}
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
                About Us
              </div>
              <h1 className="mb-4">
                We Make Your Business Smarter with Artificial Intelligence
              </h1>
              <p className="mb-4">
                In a world driven by innovation, choosing the right partner for
                your robotics needs is crucial. At Shreyasi Robotics, we stand
                out as the premier choice for businesses seeking to embrace the
                future of automation.
              </p>
              <p className="mb-4">
                Here's why choosing us is a strategic decision for your success.
              </p>
              <div className="row g-3">
                <div className="col-sm-12">
                  <h6 className="mb-3">
                    <i className="fa fa-check text-primary me-2"></i>Our Vision:
                    Pioneering the Future of Robotics
                  </h6>
                </div>
                <div className="col-sm-12">
                  <h6 className="mb-0">
                    <i className="fa fa-check text-primary me-2"></i>Our
                    Mission: Enabling Businesses with Technology
                  </h6>
                </div>
              </div>
              <div className="d-flex align-items-center mt-4">
                {/* <a className="btn btn-primary rounded-pill px-4 me-3" href="">
                  Read More
                </a> */}
                <a className="btn btn-outline-primary btn-square me-3" href="https://www.facebook.com/Passivereferral">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-outline-primary btn-square me-3" href="">
                  <i className="fab fa-twitter"></i>
                </a>
                <a className="btn btn-outline-primary btn-square me-3" href="https://www.instagram.com/bot_shreyasi/">
                  <i className="fab fa-instagram"></i>
                </a>
                <a className="btn btn-outline-primary btn-square" href="https://www.linkedin.com/company/botshreyasi">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light mt-5 py-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
              <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
                Our Services
              </div>
              <h1 className="mb-4">
                Explore our outstanding AI solutions tailored for your business.
              </h1>
              <p className="mb-4">
                As the world advances with innovation, finding the right
                robotics partner is essential. Shreyasi Robotics is the leading
                option for businesses that want to adopt automation. Here’s how
                working with us can benefit your success.
              </p>
              {/* <a className="btn btn-primary rounded-pill px-4" href="">
                Read More
              </a> */}
            </div>
            <div className="col-lg-7">
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="row g-4">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                      <div
                        className="service-item d-flex flex-column justify-content-center text-center rounded"
                        style={{ height: "400px" }}
                      >
                        <div className="service-icon btn-square">
                          <i className="fa fa-robot fa-2x"></i>
                        </div>
                        <h5 className="mb-3">Robo Wait-Staff Experience</h5>
                        <p>
                          Robo Wait-Staff by Shreyasi Robotics boosts
                          hospitality. It enhances service, simplifies
                          operations, and sets you apart. Our state-of-the-art
                          robotics ensure quality and speed, offering a special
                          and lasting dining experience.
                        </p>
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                      <div
                        className="service-item d-flex flex-column justify-content-center text-center rounded"
                        style={{ height: "400px" }}
                      >
                        <div className="service-icon btn-square">
                          <i className="fa fa-power-off fa-2x"></i>
                        </div>
                        <h5 className="mb-3">Robot Forklift</h5>
                        <p>
                          Robot Forklift by Shreyasi Robotics upgrades
                          logistics. It solves manual handling issues with
                          accurate, fast, and safe material handling. Our
                          robotics technology enables your logistics processes
                          for higher productivity and lower costs.
                        </p>
                        {/* <a className="btn px-3 mt-auto mx-auto" href="">
                          Read More
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 pt-md-4">
                  <div className="row g-4">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                      <div
                        className="service-item d-flex flex-column justify-content-center text-center rounded"
                        style={{ height: "400px" }}
                      >
                        <div className="service-icon btn-square">
                          <i className="fa fa-graduation-cap fa-2x"></i>
                        </div>
                        <h5 className="mb-3">Humanoid HR Solutions</h5>
                        <p>
                          Shreyasi Robotics offers a humanoid technology that
                          optimizes your recruitment process. From screening to
                          onboarding, our technology transforms HR practices for
                          a smooth and smart hiring experience.
                        </p>
                        {/* <a className="btn px-3 mt-auto mx-auto" href="">
                          Read More
                        </a> */}
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.7s">
                      <div
                        className="service-item d-flex flex-column justify-content-center text-center rounded"
                        style={{ height: "400px" }}
                      >
                        <div className="service-icon btn-square">
                          <i className="fa fa-brain fa-2x"></i>
                        </div>
                        <h5 className="mb-3">Predictive Analysis</h5>
                        <p>
                          Erat ipsum justo amet duo et elitr dolor, est duo duo
                          eos lorem sed diam stet diam sed stet lorem.
                        </p>
                        {/* <a className="btn px-3 mt-auto mx-auto" href="">
                          Read More
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-primary feature pt-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div
              className="col-lg-6 align-self-center mb-md-5 pb-md-5 wow fadeIn"
              data-wow-delay="0.3s"
            >
              <div className="btn btn-sm border rounded-pill text-white px-3 mb-3">
                Why Choose Us
              </div>
              <h1 className="text-white mb-4">
                We're Best in AI Industry
              </h1>
              <p className="text-light mb-4">
                In a world driven by innovation, choosing the right partner for
                your robotics needs is crucial. At Shreyasi Robotics, we stand
                out as the premier choice for businesses seeking to embrace the
                future of automation. Here's why choosing us is a strategic
                decision for your success
              </p>
              <div className="d-flex align-items-center text-white mb-3">
                <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                  <i className="fa fa-check"></i>
                </div>
                <span>Professionalism at Every Step</span>
              </div>
              <div className="d-flex align-items-center text-white mb-3">
                <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                  <i className="fa fa-check"></i>
                </div>
                <span>Future-Ready Solutions</span>
              </div>
              <div className="d-flex align-items-center text-white mb-3">
                <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                  <i className="fa fa-check"></i>
                </div>
                <span>Humanoid HR Expertise</span>
              </div>

            </div>
            <div
              className="col-lg-6 align-self-end text-center text-md-end wow fadeIn"
              data-wow-delay="0.5s"
            >
              <img className="img-fluid" src={WhyChooseUs} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
