import React from "react";
import { Link } from "react-router-dom";
import '../css/Style.css'
import '../css/bootstrap.min.css'
import '../lib/animate/animate.min.css'
import '../lib/owlcarousel/assets/owl.carousel.min.css'
import { Box, Heading } from "@chakra-ui/react";
const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark ">
      <div className="container">
        <a href="index.html" className="navbar-brand">
          <Heading color={'white'}>
            Shreyasi&nbsp; Robotics
            {/* <span className="text-secondary">.</span> */}
          </Heading>
        </a>
        <button
          type="button"
          className="navbar-toggler ms-auto me-0"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse" >
          <Box className="navbar-nav ms-auto" mr={-50}>
            <Link to={"/"} className="nav-item nav-link">
              Home
            </Link>
            <Link to={"/About"} className="nav-item nav-link">
              About
            </Link>
            <Link to={'/Services'}  className="nav-item nav-link">
              Services
            </Link>
            {/* <Link to={'/Project'} className="nav-item nav-link">
              Projects
            </Link> */}
            {/* <Box className="nav-item dropdown" >
              <a
                href="#"
                className="nav-link dropdown-toggle active"
                data-bs-toggle="dropdown"
              >
                Pages
              </a>
              <div className="dropdown-menu bg-light mt-2">
                <a href="feature.html" className="dropdown-item">
                  Features
                </a>
                <a href="team.html" className="dropdown-item">
                  Our Team
                </a>
                <a href="faq.html" className="dropdown-item active">
                  FAQs
                </a>
                <a href="testimonial.html" className="dropdown-item">
                  Testimonial
                </a>
                <a href="404.html" className="dropdown-item">
                  404 Page
                </a>
              </div>
            </Box> */}
            <Link to={'/Contact'} className="nav-item nav-link">
              Contact
            </Link>
          </Box>
          {/* <button
            type="button"
            className="btn text-white p-0 d-none d-lg-block"
            data-bs-toggle="modal"
            data-bs-target="#searchModal"
          >
            <i className="fa fa-search" />
          </button> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
