import React from "react";
import { Link } from "react-router-dom";
import '../css/Style.css'
import '../css/bootstrap.min.css'
import '../lib/animate/animate.min.css'
import '../lib/owlcarousel/assets/owl.carousel.min.css'

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="container-fluid bg-dark text-white-50 footer pt-5">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
            <Link to={'/'} className="d-inline-block mb-3">
              <h3 className="text-white">
                Shreyasi Robotics
              </h3>
            </Link>
            <p className="mb-0">
              At Shreyasi Robotics, we are at the forefront of revolutionizing
              industries with our advanced robotics technology. We bring a
              wealth of innovation and experience to each project, ensuring that
              businesses not only embrace the future but also drive its
              evolution.
            </p>
          </div>
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
            <h5 className="text-white mb-4">Get In Touch</h5>
            <p>
              <i className="fa fa-map-marker-alt me-3"></i>Mandi,Himachal Pradesh
            </p>
            {/* <p>
              <i className="fa fa-phone-alt me-3"></i>+012 345 67890
            </p> */}
            <p>
              <i className="fa fa-envelope me-3"></i>preeti.singh@botshreyasi.com
            </p>
            <div className="d-flex pt-2">
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-twitter"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="https://www.facebook.com/Passivereferral">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="https://www.youtube.com/channel/UCG2ay_StdZ5j4bF40hNi4vQ">
                <i className="fab fa-youtube"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="https://www.instagram.com/bot_shreyasi/">
                <i className="fab fa-instagram"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="https://www.linkedin.com/company/botshreyasi">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
            <h5 className="text-white mb-4">Popular Link</h5>
            <Link to={"/About"} className="btn btn-link" onClick={scrollToTop}>
              About Us
            </Link>
            <Link
              to={"/Contact"}
              className="btn btn-link"
              onClick={scrollToTop}
            >
              Contact Us
            </Link>
            <a className="btn btn-link" href="https://botshreyasi.com/TermsService">
              Privacy Policy
            </a>
            <a className="btn btn-link" href="https://botshreyasi.com/TermsService">
              Terms & Condition
            </a>
          </div>
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
            <h5 className="text-white mb-4">Our Services</h5>
            <p >
              Robo Wait-staff Experience
            </p>
            <p>
              Robot Forklift
            </p>
            <p>
              Humanoid HR Solution
            </p>
            {/* <a className="btn btn-link" href="">
              Data Science
            </a>
            <a className="btn btn-link" href="">
              Robot Technology
            </a> */}
          </div>
        </div>
      </div>
      <div className="container wow fadeIn" data-wow-delay="0.1s">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy;{" "}
              <a className="border-bottom" href="#">
                Shreyasi Robotics
              </a>
              , All Right Reserved. Designed By{" "}
              <a className="border-bottom" href="https://botshreyasi.com/">
                Bot Shreyasi
              </a>
            </div>
            <div className="col-md-6 text-center text-md-end">
              {/* <div className="footer-menu">
                <a href="">Home</a>
                <a href="">Cookies</a>
                <a href="">Help</a>
                <a href="">FAQs</a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
